import React from 'react';
import MediaCard from '../component/CoolCard';
import { RiOpenaiFill, RiFirebaseFill, RiNextjsFill} from "react-icons/ri";
import { FaReact } from "react-icons/fa6";
import { FaDocker, FaAws, FaNodeJs, FaJava, FaPython  } from "react-icons/fa";
import { SiGooglecloud, SiSocketdotio, SiExpress, SiMongodb, SiSqlite, SiFlask } from "react-icons/si";
import { GrMysql } from "react-icons/gr";





const Projects = () => {

  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <div  className='mb-9 font-bold'>
        <h1 id="projects" className="project-title">Projects</h1>
      </div>
      <div className="flex justify-evenly items-center">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
          <div>
           <MediaCard 
           projectImage={'/images/Celia.png'} 
           projectDescription={'Voice-based web app for practicing behavioral interviews with agent Celia'} 
           projectTitle={'Celia - AI Job Interview'} projectLink = {'https://github.com/food0903/AI-Job-Interview'} 
           demoLink={'https://celiaai.netlify.app/'} 
           TechStack={[
            () => <ColoredIcon color='#0ea982' Icon={RiOpenaiFill} />,
            () => <ColoredIcon color='#2596be' Icon={FaReact}/>,
            () => <ColoredIcon color='#ffcb2b' Icon={RiFirebaseFill}/>,
            () => <ColoredIcon color='#006db8' Icon={FaDocker}/>,
            () => <ColoredIcon color='#4285f4' Icon={SiGooglecloud}/>
           ]} />
          </div>
          <div>
           <MediaCard 
              projectImage={'/images/Viewmo.jpeg'} 
              projectDescription={'Web application that analyzes user\'s emotions while the user watches a video'} 
              projectTitle={'Viewmo - Monitor viewer'} 
              projectLink={'https://github.com/flotoria/Viewmo'} 
              TechStack={[
                () => <ColoredIcon color='#00000' Icon={RiNextjsFill} />,
                () => <ColoredIcon color='#ff9900' Icon={FaAws}/>,
                () => <ColoredIcon color='#006db8' Icon={SiSocketdotio}/>,
                () => <ColoredIcon color='#00618a' Icon={GrMysql}/>,
               ]}/>
          </div>
          <div>
            <MediaCard 
                projectImage={'/images/FoodStore.png'} 
                projectDescription={'Full stack CRUD web application that simulate ecommerce platform'} 
                projectTitle={'Online Food Store'} 
                Frontend={'https://github.com/food0903/foodstorefrontend'} 
                Backend={'https://github.com/food0903/foodstorebackend'} 
                TechStack={[
                  () => <ColoredIcon color='#539e43' Icon={FaNodeJs}/>,
                  () => <ColoredIcon color='#00000' Icon={SiExpress} />,
                  () => <ColoredIcon color='#2596be' Icon={FaReact}/>,
                  () => <ColoredIcon color='#00ed64' Icon={SiMongodb}/>,
                ]}/>
          </div>
          <div>
            <MediaCard 
              projectImage={'/images/Planner.png'} 
              projectDescription={'A Schedule planner that help user organize their time developed on JavaFX'} 
              projectTitle={'Schedule Planner'} projectLink={'https://github.com/trungtran1234/java_planner'}
              TechStack={[
                    () => <ColoredIcon color='#5382a1' Icon={FaJava}/>,
                    () => <ColoredIcon color='#208bcf' Icon={SiSqlite}/>,
                    () => <ColoredIcon color='#ff9900' Icon={FaAws}/>,
                  ]}/>
          </div>
          <div>
           <MediaCard 
            projectImage={'/images/CardGame.png'} 
            projectDescription={'A Dixit inspired card game, now you can play online with your friends!'} 
            projectTitle={'Celestial Narrative'} 
            projectLink={'https://github.com/food0903/celestialNarratives'}
            TechStack={[
                    () => <ColoredIcon Icon={SiFlask}/>,
                    () => <ColoredIcon color='#006db8'  Icon={SiSocketdotio}/>,
                    () => <ColoredIcon Icon={FaPython}/>,
                  ]}/>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColoredIcon = ({ color, Icon}) => {
  return <Icon color={color} size ={24}/>;
};

export default Projects;

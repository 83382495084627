import React from 'react';
import { TypeAnimation } from 'react-type-animation';

function Pfp() {
  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <div className="rounded-full overflow-hidden w-80 h-80">
        <img src="/images/ProfilePic.jpeg" alt="Profile Picture"  className="w-full h-full object-cover rounded-full transition duration-300 transform hover:border border-darkGreen border-2"/>
      </div>
      <div className="mt-4 text-center">
        <p className="text-coolBlack text-3xl font-semibold">HEY, MY NAME IS <span className="text-lightGreen">PHUC</span></p>
        <TypeAnimation
          className="text-coolBlack"
          cursorColor="#4B5563"
          sequence={['WELCOME TO MY PAGE']}
          interval={20} 
        />
      </div>
    </div>
  );
}

export default Pfp;

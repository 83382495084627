import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function MediaCard({projectImage, projectTitle, projectDescription, projectLink, demoLink, Frontend, Backend, TechStack}) {
  return (
    <Card sx={{ maxWidth: 345}}>
      <CardMedia
        sx={{ height: 200 }}
        image= {projectImage}
        title= {projectTitle}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {projectTitle}
        </Typography>
        <Typography gutterBottom variant="body1" color="text.secondary">
          {projectDescription}
        </Typography>
        <div className='flex flex-row gap-2'>
          {TechStack && TechStack.map((Icon, index) => (
            <Icon key={index} color="#00AEEF" size={24} />
          ))}
        </div>
      </CardContent>
      
      <CardActions>
        {projectLink && (
        <Button size="small" href= {projectLink} target='_blank'>Project Link</Button>
        )}
        {demoLink && (
          <Button size="small" href={demoLink} target='_blank'>Demo Link</Button>
        )}
        {Frontend && (
          <Button size="small" href={Frontend} target='_blank'>Frontend Link</Button>
        )}
        {Backend && (
          <Button size="small" href={Backend} target='_blank'>Backend Link</Button>
        )}
      </CardActions>
    </Card>
  );
}
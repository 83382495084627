import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // maybe needed later on 
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseSharp } from "react-icons/io5";

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [pdfIsOpen, setPdfIsOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  };

  const handlePNClick = () => {
    if (location.pathname === '/') {
      scrollToTop();
    }
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        behavior: "smooth",
        top: element.offsetTop
      });
    }
  };

  const handleLinkClick = (id) => {
    if (location.pathname === '/') {
      scrollToSection(id);
    }
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <nav className="fixed top-7 left-0 right-0 mx-auto w-2/3 bg-medGreen bg-opacity-80 rounded-[50px] flex justify-between items-center p-2 z-10">
      <h1 className="text-3xl font-bold text-paleWhite">
        <div className="border-4 border-paleWhite rounded-full h-12 w-12 flex justify-center items-center mr-2">
          {location.pathname === '/' ? (
            <a href="#home" onClick={handlePNClick}>PN</a>
          ) : (
            <Link to="/" onClick={handlePNClick}>PN</Link>
          )}
        </div>
      </h1>
      <div className="hidden md:flex items-center">
        <ul className="flex space-x-4">
          <li>
            <a href="#about" className="text-coolBlack hover:bg-lightGreen font-bold" onClick={() => handleLinkClick("about")}>About</a>
          </li>
          <li>
            <a href="#projects" className="text-coolBlack hover:bg-lightGreen font-bold" onClick={() => handleLinkClick("projects")}>Projects</a>
          </li>
          <li>
            <a href="https://drive.google.com/file/d/1sW_LRgcsGg7LcVFMm2l6rBPUkgoLkQUU/view?usp=sharing" className="text-coolBlack hover:bg-lightGreen font-bold" target="_blank" rel="noopener noreferrer">Resume</a>
          </li>
        </ul>
      </div>
      <RxHamburgerMenu className="md:hidden text-paleWhite text-3xl" onClick={() => setIsOpen(!isOpen)} />
      <div className={`md:hidden fixed top-0 left-0 w-full h-full bg-medGreen bg-opacity-80 flex flex-col justify-center items-center z-20 ${isOpen ? '' : 'hidden'}`}>
        <IoCloseSharp className="absolute top-4 right-4 text-paleWhite text-3xl cursor-pointer" onClick={() => setIsOpen(false)} />
        <ul className="flex flex-col items-center space-y-4">
          <li>
            <a href="#about" className="text-coolBlack hover:bg-lightGreen font-bold" onClick={() => handleLinkClick("about")}>About</a>
          </li>
          <li>
            <a href="#projects" className="text-coolBlack hover:bg-lightGreen font-bold" onClick={() => handleLinkClick("projects")}>Projects</a>
          </li>
          <li>
            <a href="https://drive.google.com/file/d/1Ouk95FCBF_pmYZTCtMgzKqOcvHJGqK9z/view?usp=sharing" className="text-coolBlack hover:bg-lightGreen font-bold" target="_blank" rel="noopener noreferrer">Resume</a>
          </li>
        </ul>
      </div>
    </nav>
  );
  
};

export default Navbar;

import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../component/Card';
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import "./About.css";

const About = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    // Custom NextArrow component
    const NextArrow = ({ onClick }) => {
        return (
          <div className="slick-arrow next-arrow absolute top-1/2 transform -translate-y-1/2 right-0 z-10 cursor-pointer rounded-full bg-jade p-2 transition-all duration-300 hover:opacity-65 hover:translate-x-1" onClick={onClick}>
          <GoArrowRight />
        </div>
        );
    };

    // Custom PrevArrow component
    const PrevArrow = ({ onClick }) => {
        return (
          <div className="slick-arrow prev-arrow absolute top-1/2 transform -translate-y-1/2 left-0 z-10 cursor-pointer rounded-full bg-jade p-2 transition-all duration-300 hover:opacity-65 hover:-translate-x-1" onClick={onClick}>
          <GoArrowLeft />
      </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: 0,
        nextArrow: <NextArrow />, // Custom next arrow component
        prevArrow: <PrevArrow />, 
        beforeChange: (current, next) => {
            setActiveSlideIndex(next);
        }
    };

    return (
        <div id="about" className="flex justify-center items-center h-screen">
            <div className="w-full sm:w-7/12 px-4 relative">
                <h1 className="text-3xl font-bold mb-9 text-center">About</h1>
                <Slider {...settings}>
                    <div className={activeSlideIndex === 0 ? "slide activeSlide" : "slide"}>
                        <Card
                            title="About me"
                            description=" My full name is Phuc Nguyen (It's pronounced Phook). I am currently a San Jose State University student pursuing full-stack web design. I am also interested in cybersecurity and would like to discuss any opportunities available."
                        />
                    </div>
                    <div className={activeSlideIndex === 1 ? "slide activeSlide" : "slide"}>
                        <Card
                            title="More about me.."
                            description="I am a Junior pursuing Software Engineering at SJSU. I've been into programming since high school and I'm always eager to stay updated with the latest technology trends. What I find most fascinating about Computer Science is its ever-evolving nature. It's a field where learning is a continuous journey, and as new technologies emerge, there's always something new to explore and discover. I gain my experience mostly from personal and team projects"
                        />
                    </div>
                    <div className={activeSlideIndex === 2 ? "slide activeSlide" : "slide"}>
                        <Card
                            title="Experience"
                            description={
                                <div>
                                    <p>
                                        <strong>Software Development Intern (Summer 2024):</strong>
                                        Frontend Intern that specializes in React Development at Jonajo Consulting
                                    </p>
                                    <p>
                                        <strong>SCE Development Team (Present):</strong>
                                        Contributed to Message API project to create and deploy chatroom on the main sce.sjsu.edu website using Server-Sent Events (SSE) and use C++ to program Arduino to control the LCD screen using esp32 microcontroller
                                    </p>
                                </div>
                            }
                        />
                    </div>
                    <div className={activeSlideIndex === 3 ? "slide activeSlide" : "slide"}>
                        <Card
                            title="Technology"
                            description={
                                <div>
                                    <p>
                                        <strong>Programming languages:</strong> Python, Java, JavaScript, Typescript, C++, HTML, CSS, SQL, Bash
                                    </p>
                                    <p>
                                        <strong>Technology:</strong> React, Node, JUnit, Flask, Nextjs, JavaFx, Postman, MongoDB, Google Cloud, AWS, WebSockets
                                    </p>
                                    <p>
                                        <strong>Text editor/IDE:</strong> Visual Studio Code, IntelliJ IDEA, Eclipse, Atom.
                                    </p>
                                    <p>
                                        <strong>Others:</strong> Linux commands, Git, Wireshark
                                    </p>
                                </div>
                            }
                        />
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default About;

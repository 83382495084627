// Footer.js
import React from 'react';
import { AiFillLinkedin, AiFillGithub, AiFillMessage } from 'react-icons/ai';

const Footer = () => {
  return (
    <div className="fixed bottom-9 left-0 right-0 flex justify-center">
      <footer className="bg-medGreen p-2 rounded-full w-1/7 bg-opacity-90">
        <div className="container mx-auto flex justify-center items-center space-x-4">
          <a href="https://www.linkedin.com/in/phuc-nguyen-9b3024289/" target="_blank" rel="noopener noreferrer" className="hover:bg-lightGreen active:bg-lightGreen focus:outline-none focus:ring focus:ring-lightGreen focus:ring-offset-transparent hover:-translate-y-1 transform transition ">
            <AiFillLinkedin size={36} color='#0077b7' />
          </a>
          <a href="https://github.com/food0903" target="_blank" rel="noopener noreferrer" className="hover:bg-lightGreen active:bg-lightGreen focus:outline-none hover:-translate-y-1 transform transition focus:ring focus:ring-lightGreen focus:ring-offset-transparent rounded-full">
            <AiFillGithub size={36} className="text-paleWhite" />
          </a>
          <a href="mailto:tan.phuc.2003.123@gmail.com" className="hover:bg-lightGreen active:bg-lightGreen focus:outline-none focus:ring focus:ring-lightGreen hover:-translate-y-1 transform transition focus:ring-offset-transparent rounded-full">
            <AiFillMessage color='beige' size={36} className="text-paleWhite" />
          </a>
        </div>  
      </footer>
    </div>
  );
};

export default Footer;

import React from 'react';

const Card = ({ title, description }) => {
    return (
        <div className='about-me-card' style={{ height: "300px", overflow: "auto" }}>
            <h1 className="text-3xl font-semibold mb-4 text-center relative overflow-hidden">
                <span className="hover:underline ">{title}</span>
            </h1>
            <p className="text-lg mb-4">{description}</p>
        </div>
    );
};

export default Card;

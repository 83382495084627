import React from 'react';
import Pfp from './Pfp';
import Projects from './Projects';
import About from './About';

function Home() {
  return (
    <div>
        <div>
          <Pfp/>
        </div>
        <div>
          <About/>
        </div>
          <Projects/>
       
    </div>
  );
}

export default Home;

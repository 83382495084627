// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // these hook are saved if there is an external page later on
import Navbar from './component/Navbar'; 
import Home from './pages/Home'; 
import Footer from './component/footer';
import { Helmet } from 'react-helmet';
import { Analytics } from "@vercel/analytics/react"


const App = () => {
  return (
    <>
    <Router>
      <div>
        <Helmet>
          <title>Phuc Nguyen</title>
        </Helmet>
        <Navbar /> 
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
    <Analytics/>
    </>
  );
};

export default App;
